*{
  margin: 0;
  padding: 0;
}

/* Header.css  Start*/
.header {
    background-color: #ED1B24; /* Set the background color of the navbar */
        
  }
  .navbar-nav {
    margin-left: auto;
   
  }
  
  .navbar a{
      text-decoration: none;
      padding: 20px;
      font-family: "Sedan", serif;
      /* font-family: "Permanent Marker", cursive; */
      color: #fff; /* Set the link text color */
      font-size: 27px;
      text-transform:capitalize;
      font-weight: 600;
      font-style: normal;
     
    }
    .navbar a:hover{
      color: #385399
    }
  
   #Headericons{
  
    color: #fff; /* Set the link text color */
    font-size: 30px;
    text-decoration: none;
    
    
   }
  
   #Headericons :hover{
    color: #385399
  

    /* Logo Start */
   }
   #Logo {
    max-width: 15%;
    height: auto;
  }
  
  /* Media query for screens up to 767px (typical mobile devices) */
  @media only screen and (max-width: 767px) {
    /* Flexbox layout for mobile */
    body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    #Logo {
      max-width: 80%; /* Adjust as needed */
      margin-left: 2px; /* Adjust margin for spacing */
    }
  }

  /* Logo End */