*{
  margin: 0;
  padding: 0;
}


/* heading Start */


h1, h2, h3, h4, h5 {

  font-family: "Sedan", serif;
 font-size: 30px !important;
  font-weight: 500 ;
  font-style: normal;
  color: rgb(67, 67, 239) !important; /* Add !important to prioritize this rule */
}

/* heading End */


/* text Fonts Start */
  p{
    
    font-family: "Sedan", serif;
    color: rgb(7, 0, 0);
    font-size: 25px;
    font-weight: 200px;
  }
  /* text Fonts End */



/* carousel start */
  .carousel-item img {
    
   width: 100%;
   height: auto;
  }
  
   .carousel-caption{
    display: flex;
    align-items: center; 
    justify-content: center; 
    height: 100vh; 
  }
 
  /* carousel End */

 /* background image start */
.bg{
  width: 100%;
  height: 100vh;
  background-image: url(/public/img/15.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.bg1{
  width: 100%;
  height: 100vh;
  background-image: url(/public/img/16.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
/* background image End */


/* our products start */
.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  display: block;
  width: 100%;
  height: 250px;
  transition: transform 0.3s; /* Add a transition for smooth scaling */
}

.image-container:hover img {
  transform: scale(1.1); /* Scale the image up by 10% on hover */
}

/* our products End */


/* images Start */
.col-lg-6 img:hover {
  transition: transform 0.3s; /* Add a transition for smooth scaling */
  transform: scale(1.05); /* Scale the image up by 10% on hover */
}
/* images End */




/* contact us start */
/* Style for the form container */
#form {
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}



/* Style for the "Send Message To Us" text */


/* Style for input fields and textareas */
input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Style for the submit button */
button.btn {
  background-color: #007bff;           
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Merienda', cursive;
}

/* Style for the submit button on hover */
button.btn:hover {
  background-color:rgb(67, 67, 239)

  
}

/* Responsive styling for smaller screens */
@media screen and (max-width: 600px) {
  #form {
    width: 100%;
  }
}









/* WhatsApp start */
.whatsapp-float{

  position: fixed;
  bottom: 40px;
  right: 20px;

}

/* WhatsApp End */



/* Footer Start */

#Footer{
background-color:#ED1B24;
}
#Footer h3 {
  font-family: "Satisfy", cursive;
  color: #fff !important;
  font-size: 35px;
}

#Footer p{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: rgb(7, 0, 0);
    font-size: 18px;
    font-weight: 500;
}

/* Footer End */



